import React, { useState } from "react";

export function GochiKidPreviewImageReceiptModal(props) {
  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => props.onClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">
          <div className="title required-dot">
            <div className="dot-red"></div>
            レシート登録 画像確認
          </div>
        </div>
        <div className={`modal-body`}>
          <div className="preview-image-box">
            <img src={props.imageReceiptFileUrl} width="100%" height="auto" />
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-rect btn-submit"
              onClick={() => props.onSubmit()}
            >
              読み取る
            </button>
            <label
              className="btn btn-outline-rect btn-text"
              htmlFor={`img-input-receipt`}
            >
              <input
                name="img-input"
                id={`img-input-receipt`}
                className="input-gochi-kid-receipt"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  props.onChangeImgUpload(e, props.gochiKidUseIdChoose);
                  e.target.value = null;
                }}
              />
              画像を再選択
            </label>
          </div>
        </div>
      </div>
      <div
        onClick={() => props.onClose()}
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
