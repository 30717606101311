import moment from "moment";
import React, { useState } from "react";

export function GochiKidErrorUploadReceiptModal(props) {
  const handleReUploadReceipt = async (e) => {
    props.onClose();
    await props.onChangeImgUpload(e, props.gochiKidUseIdChoose);
  };

  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-upload-receipt-error show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-upload-receipt-error"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => props.onClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">
          <div className="title required-dot">
            <div className="dot-red"></div>
            レシート登録 画像確認
          </div>
        </div>
        <div className={`modal-body`}>{props.message}</div>
        <div className="modal-footer">
          <label
            className="btn btn-rect btn-submit"
            htmlFor={`img-input-receipt`}
          >
            <input
              name="img-input"
              id={`img-input-receipt`}
              className="input-gochi-kid-receipt"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleReUploadReceipt(e);
                e.target.value = null;
              }}
            />
            画像を再選択
          </label>
        </div>
      </div>
      <div
        onClick={() => {
          props.onClose();
        }}
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
