import React, { useState } from "react";

export function GochiKidReceiptConfirmModal(props) {
  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-confirm show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-confirm"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => props.onClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">レシート登録 確認</div>
        <div className={`modal-body`}>登録を確定してもよろしいですか？</div>
        <div className="modal-footer">
          <button
            className="btn btn-rect btn-submit"
            onClick={() => props.onSubmit()}
          >
            登録を確定する
          </button>
          <button
            onClick={() => props.onClose()}
            className={"btn btn-outline-rect"}
          >
            前の画面に戻る
          </button>
        </div>
      </div>
      <div
        onClick={() => {
          props.onClose();
        }}
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
