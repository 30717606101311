/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GiftService from "../../../api/services/GiftService";
import ShopService from "../../../api/services/ShopService";
import { Link } from "react-router-dom";
import { MenuInfoShop } from "./MenuInfoShop";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "./SwiperConfigs";
import { SliderPagination } from "./SliderPagination";
import { InfoBox } from "./InfoBox";
import queryString from "query-string";
import { useWindowSize } from "../../../utils";
import UserService from "../../../api/services/UserService";
import moment from "moment";

export function ShopDetailsGochiKid(props) {
  const shopId = props.shopId;
  const [listMenus, setListMenus] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);
  const [menuSituations, setMenuSituations] = useState([]);
  const [menuUserTargets, setMenuUserTargets] = useState([]);
  const [gochiBillShopMessages, setGochiBillShopMessages] = useState([]);
  const [couponShopMessages, setCouponShopMessages] = useState([]);

  useEffect(() => {
    getListMenus();
  }, [shopId]);

  async function checkUserAvailable (accessCode) { 
    try {
        const { data } = await UserService.checkUserAvailable(accessCode);   
        return data;
    } catch (error) { 
        alert('エラーが発生しました');
    }
  };

  async function checkUser (accessCode) {
    const isAvailable = await checkUserAvailable(accessCode);
      if (isAvailable.userId === null){
        window.location.href = "/login";
      }
  }

  useEffect(() => {
    if (props.accessCode) {
      checkUser(props.accessCode);
    }
  }, [props.accessCode]);

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  function getListMenus() {
    GiftService.getListMenuGochiKidOfShop({ shopId, gochiKidGroupId: props.gochiKidGroupId }, props.accessToken, props.checkTokenExpired)
      .then((response) => {
        if (response.status === 200) {
          setListMenus(response.data.list);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET LIST MENUS ERROR: ", e);
      });
  }

  function getGochiBillShopMessages() {
    ShopService.getGochiBillShopMessages(shopId, 1, 15)
      .then((response) => {
        if (response.status === 200) {
          setGochiBillShopMessages(response.data.list);
        } else if (response.status === 404) {
          // TODO
        }
      })
      .catch((e) => {
        console.error("GET LIST GOCHI BILL SHOP MESSAGES ERROR: ", e);
      });
  }

  const shopDetails = props.shopDetails;

  useEffect(() => {
    const googleMapFrame = document.getElementById("google-map");
    const url = `https://maps.google.co.jp/maps?output=embed&q=${
      shopDetails.prefectures ? shopDetails.prefectures + " " : ""
    }${shopDetails.city ? shopDetails.city + " " : ""}${
      shopDetails.address ? shopDetails.address + " " : ""
    }${shopDetails.building || ""}`;
    console.log("Google maps url:", url);
    (
      googleMapFrame?.contentWindow || googleMapFrame?.documentWindow
    ).location.replace(url);
  }, [shopDetails]);

  const dayUseWeek = shopDetails?.shopSetting?.dayUseWeek && JSON.parse(shopDetails?.shopSetting?.dayUseWeek).sort() || [];

  const dayInWeek = [
    {id: 1, name: '月'},
    {id: 2, name: '火'},
    {id: 3, name: '水'},
    {id: 4, name: '木'},
    {id: 5, name: '金'},
    {id: 6, name: '土'},
    {id: 7, name: '日'},
  ];

  let dayUseWeekStr = '';
  if (dayUseWeek) {
    for (let i = 0; i < dayInWeek.length; i++) {
      if (dayUseWeek.includes(dayInWeek[i].id)) {
        dayUseWeekStr += dayInWeek[i].name + ((dayInWeek[i].id === dayUseWeek[dayUseWeek.length - 1]) ? '' : ',');
      }
    }
    if (dayUseWeek.length > 0) {
      dayUseWeekStr += '/';
    }
  }
  if (shopDetails.shopSetting?.validHoliday === 1) {
    dayUseWeekStr += '祝日利用可';
  } else if (shopDetails.shopSetting?.validHoliday === 0) {
    dayUseWeekStr += '祝日利用不可';
  } else if (!shopDetails.shopSetting) {
    dayUseWeekStr = '制限なし';
  }

  return (
    <>
      {shopDetails.historyDonate && shopDetails.historyDonate.length > 0 && (
        <section className="section coupon-shop-message mt-48px">
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">ご支援者からの応援メッセージ</h2>
            </div>
          </div>
          <div className="section-body">
            <div className="card-group card-group-comment ">
              {shopDetails.historyDonate
                .map((item) => (
                <div
                  key={item.prePaymentId}
                  className="card card-comment card-elipsis"
                >
                  <p className="comment pre-line">
                    {item.donateMessage?.length >= 95
                      ? item.donateMessage?.slice(0, 94) + "…"
                      : item.donateMessage}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {props.showListMenu && (
        <section className={listMenus !== null ? "section mt-48px" : "section mt-48px loading"}>
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">{shopDetails.shopName}で利用できるこどもごちめしメニュー</h2>
            </div>
          </div>
          <div className="section-body">
            <div>
              <button
                className="btn btn-sm btn-rect btn-menu-price"
                onClick={() => {
                  //if (props.numUserKidUse > 0) {
                    if (props.numberSlotCanUse === 0) {
                      const errorText = (
                        <div>
                          以下いずれかの理由により、ご利用できません。
                          <br />
                          ・利用可能残数がない
                          <br />
                          ・利用可能曜日（時間）ではない
                          <br />
                          ・支援金が不足している
                        </div>
                      );
                      props.handShowErrorUseGochiKidPopup(errorText);
                    } else if (props.numUserKidUse === 1) {
                      if (shopDetails.gochiKidReceipt === 1) {
                        props.setGochiKidReceiptShopChoice(1);
                        props.handleShowPopupAcceptPolicyGochiKidReceipt(props.choiceShopId, shopDetails.gochiKidUnlimit, null);
                      } else {
                        props.setGochiKidReceiptShopChoice(0);
                        props.handleOpenInputGochiKidPriceModal(props.choiceShopId, shopDetails.gochiKidUnlimit);
                      }
                    } else {
                      props.handleOpenSelectUserKidModal(props.choiceShopId, null, shopDetails.gochiKidUnlimit, null, shopDetails.gochiKidReceipt);
                    }
                  //}
                  //else {
                  //  props.handShowErrorUseGochiKidPopup("本日の食堂利用回数を超えました。");
                  //}
                }}
              >金額を指定して利用する</button>
            </div>
            <div className="products-group list-menu-gochi_kid mt-3">
              {listMenus?.map((item) => (
                <MenuInfoShop
                  key={item.menuId}
                  menu={item}
                  isShopDetailScreen
                  choiceShopId={props.choiceShopId}
                  handleOpenSelectUserKidModal={props.handleOpenSelectUserKidModal}
                  numUserKidUse={props.numUserKidUse}
                  choiceUserKidId={props.choiceUserKidId}
                  choiceUserKid={props.choiceUserKid}
                  handShowErrorUseGochiKidPopup={props.handShowErrorUseGochiKidPopup}
                  accessToken={props.accessToken}
                  accessCode={props.accessCode}
                  gochiKidGroupId={props.gochiKidGroupId}
                  numberSlotCanUse={props.numberSlotCanUse}
                  setGochiKidReceiptShopChoice={props.setGochiKidReceiptShopChoice}
                  handleShowPopupAcceptPolicyGochiKidReceipt={props.handleShowPopupAcceptPolicyGochiKidReceipt}
                  gochiKidReceipt={shopDetails.gochiKidReceipt}
                  gochiKidUnlimit={shopDetails.gochiKidUnlimit}
                />
              ))}
            </div>
          </div>
        </section>
      )}
      {shopDetails.historyUseMenu && shopDetails.historyUseMenu.length > 0 && (
        <section className="section">
          <div className="section-header">
            <div className="heading-lg">
              <h2 className="title">{shopDetails.shopName}への応援メッセージ</h2>
            </div>
          </div>
          <div className="section-body">
            <div className={`slider-coment slider-gochi-kid-shop
              ${shopDetails.historyUseMenu.length <= getSlidePerView(innerWidth, swiperConfigs.comment) ? 'card-group card-group-comment' : ''}`} id="slider-coment">
              {shopDetails.historyUseMenu.length <= getSlidePerView(innerWidth, swiperConfigs.comment) ? (
                <>
                  {shopDetails.historyUseMenu
                    .map((item) => (
                      <div
                        key={item.gochiKidUseId}
                        className="card card-comment card-elipsis"
                      >
                        <p className="comment pre-line">
                          {item.thanksMessage?.length >= 120
                            ? item.thanksMessage?.slice(0, 119) + "…"
                            : item.thanksMessage}
                        </p>
                      </div>
                  ))}
                </>
              ) : (
                <>
                  <Swiper
                    className="slider-coment-container p-2"
                    {...swiperConfigs.comment}
                  >
                    <div className="swiper-wrapper">
                      {shopDetails.historyUseMenu
                        .map((item) => (
                          <SwiperSlide
                            className="swiper-slide"
                            key={item.gochiKidUseId}
                          >
                            <div className="card card-comment card-elipsis">
                              <p className="comment pre-line font-weight-normal">
                                {/* <span className="caution">最大120文字</span> */}
                                {item.thanksMessage?.length >= 120
                                  ? item.thanksMessage?.slice(0, 119) + "…"
                                  : item.thanksMessage}
                              </p>
                            </div>
                          </SwiperSlide>
                        ))}
                    </div>
                  </Swiper>
                  <SliderPagination />
                </>
              )}
            </div>
          </div>
        </section>
      )}
      
      <section className={shopDetails.shopId ? "section shop-info mt-48px" : "section shop-info mt-48px loading"}>
        <div className="section-header">
          <div className="heading-lg">
            <h2 className="title">店舗詳細</h2>
          </div>
        </div>
        <div className="section-body">
          <dl className="list-information">
            <div className="list-information-item">
              <dt className="title">店舗名</dt>
              <dd className="text">
                {shopDetails.shopName}
                {shopDetails.shopNameKana && `（${shopDetails.shopNameKana}）`}
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">住所</dt>
              <dd className="text">
                {`${shopDetails.prefectures || ""} ${shopDetails.city || ""} ${
                  shopDetails.address || ""
                } ${shopDetails.building || ""}`}
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">TEL</dt>
              <dd className="text">
                <a href={`tel:${shopDetails.phone}`}>{shopDetails.phone}</a>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">最寄り駅</dt>
              <dd className="text">{shopDetails.nearestStation}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">アクセス</dt>
              <dd className="text">{shopDetails.access}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">利用可能曜日</dt>
              <dd className="text">{dayUseWeekStr.length === 0 ? '制限なし' : dayUseWeekStr}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">不定休</dt>
              <dd className="text">
                {shopDetails.shopSetting?.shopHolidays && shopDetails.shopSetting?.shopHolidays.map((holiday, index) => {
                  return (
                    <span key={holiday.shopHolidayId}>{`${moment(holiday.holiday).format('YYYY年MM月DD日')}${shopDetails.shopSetting?.shopHolidays.length - 1 === index ? '' : ', '}`}</span>
                  )
                })}
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">利用可能時間</dt>
              <dd className="text">{(shopDetails.shopSetting?.useStartTime && shopDetails.shopSetting?.useEndTime) ? (moment(shopDetails.shopSetting.useStartTime, 'HH:mm:ss').format('HH:mm') + ' ~ ' + (moment(shopDetails.shopSetting.useEndTime, 'HH:mm:ss').format('HH:mm'))) : '制限なし'}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">利用上限数</dt>
              <dd className="text">{shopDetails.shopSetting?.limitNumUsedDay ? shopDetails.shopSetting.limitNumUsedDay + '人' : "制限なし"}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">ジャンル</dt>
              <dd className="text">{shopDetails.genre?.genreName}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">定休日</dt>
              <dd className="text">{shopDetails.holiday}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">価格帯</dt>
                <dd className="text">
                  <ul className="list-inline">
                    <li>{shopDetails.priceRange}</li>
                  </ul>
                </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">禁煙/喫煙</dt>
              <dd className="text">{shopDetails.smoking}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">座席</dt>
              <dd className="text">{shopDetails.numberSit}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">駐車場</dt>
              <dd className="text">{shopDetails.parking}</dd>
            </div>
            <div className="list-information-item">
              <dt className="title">タグ</dt>
              <dd className="text">
                <ul className="list-inline">
                  {shopDetails.shopTag?.map((item) => (
                    <li key={item.shopTagId}>
                      <Link
                        to={`/products?${queryString.stringify({
                          keyword: item.tagName && (item.tagName.indexOf("#") === 0 || item.tagName.indexOf("＃") === 0) ? (item.tagName.indexOf("#") === 0 ? item.tagName.replace("#", '') : item.tagName.replace("＃", '') ) : item.tagName,
                        })}`}
                      >
                        {item.tagName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">SNS</dt>
              <dd className="text">
                <ul className="list-inline">
                  {shopDetails.snsTwitter && (
                    <li>
                      <a
                        href={shopDetails.snsTwitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </li>
                  )}
                  {shopDetails.snsFacebook && (
                    <li>
                      <a
                        href={shopDetails.snsFacebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                  )}
                  {shopDetails.snsInstagram && (
                    <li>
                      <a
                        href={shopDetails.snsInstagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
            <div className="list-information-item">
              <dt className="title">公式サイト</dt>
              <dd className="text">
                {shopDetails.webPage && (
                  <a
                    href={shopDetails.webPage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shopDetails.webPage}
                  </a>
                )}
              </dd>
            </div>
          </dl>
        </div>
        <figure className="map-area">
          {/* GoogleMap */}
          <iframe
            id="google-map"
            title={shopDetails.shopName}
            src={""}
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            allow="geolocation"
          />
        </figure>
      </section>
    </>
  );
}
