import React, { useEffect, useState } from "react";
import { SelectUserKidModal } from "../../common/SelectUserKidModal";
import { ModalGroup } from "../../common/ModalGroup";
import { InputGochiKidPriceModal } from "../../common/InputGochiKidPriceModal";
import moment from "moment";

export function GochiKidUsedDetail(props) {
  const item = props.itemGochiKidUsed;
  const [showSelectUserKid, setShowSelectUserKid] = useState(false);
  const [showInputGochiKidPrice, setShowInputGochiKidPrice] = useState(false);
  const [choiceShopId, setChoiceShopId] = useState(false);
  const [choiceMenuId, setChoiceMenuId] = useState(false);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [tax8PercentTotal, setTax8PercentTotal] = useState(0);
  const [tax10PercentTotal, setTax10PercentTotal] = useState(0);

  useEffect(() => {
    props.getUserKidCanUses(item.gochiKidGroupId)

    if (item.receipt) {
      sumAllTotal(item.receipt.receiptDetails);
    }
  }, [item]);

  function handleOpenSelectUserKid(shopId, menuId) {
    setShowSelectUserKid(true);
    setChoiceShopId(shopId);
    setChoiceMenuId(menuId);
  }

  function handleOpenInputGochiKidPriceModal(shopId) {
    setShowInputGochiKidPrice(true);
    setChoiceShopId(shopId);
    setChoiceMenuId(null);
    props.setUseAmount(null);
  }

  function handleCloseInputGochiKidPriceModal() {
    setShowInputGochiKidPrice(false);
    if (props.numUserKidUse > 1) {
      setShowSelectUserKid(true);
    }
  }

  function handleCloseModalGroup() {
    setShowSelectUserKid(false);
    setShowInputGochiKidPrice(false);
    props.handleClosePopupGochiKidUsedDetail(true);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function sumAllTotal(arrayNumber) {
    // Calulate taxMount, total, and subtotal of all items that were not included tax
    const sumNotTaxed = withAllItemsNotTaxed(arrayNumber);
    let taxAmount8NotTaxed = 0;
    let taxAmount10NotTaxed = 0;
    let sumSubTotalNotTaxed = 0;
    let sumTotalNotTaxed = 0;
    if (sumNotTaxed) {
      taxAmount8NotTaxed = Math.floor(sumNotTaxed.sumItemTax8 * 0.08);
      taxAmount10NotTaxed = Math.floor(sumNotTaxed.sumItemTax10 * 0.1);
      sumSubTotalNotTaxed = sumNotTaxed.sumSubTotal;

      sumTotalNotTaxed =
        sumSubTotalNotTaxed + taxAmount8NotTaxed + taxAmount10NotTaxed;
    }

    // Calulate taxMount, total, and subtotal of all items that were included tax
    const sumTaxed = withAllItemsTaxed(arrayNumber);
    let taxAmount8Taxed = 0;
    let taxAmount10Taxed = 0;
    let sumSubTotalTaxed = 0;
    let sumTotalTaxed = 0;
    if (sumTaxed) {
      // Công thức ban đầu là Math.floor(((sumTaxed.sumItemTax8) / 1.08) * 0.08);
      // nhưng do phép tính "sumTaxed.sumItemTax8) / 1.08" bị lỗi phép chia với phân số (ví dụ 1080/1.08 đúng ra phải bằng 1000 nhưng máy tính tính ra thành 999.999999999)
      // nên để tránh lỗi trên thì sẽ nhân cả tử và mẫu vs 100 để k phải làm phép tính chia cho số thập phân. Sửa tương tự vs taxAmount10Taxed
      // See more: https://stackoverflow.com/questions/15383376/javascript-division-giving-wrong-answer
      taxAmount8Taxed = Math.floor(((sumTaxed.sumItemTax8 * 100) / 108) * 0.08);
      taxAmount10Taxed = Math.floor(((sumTaxed.sumItemTax10 * 10) / 11) * 0.1);
      sumTotalTaxed = sumTaxed.sumTotal;

      sumSubTotalTaxed = sumTotalTaxed - taxAmount8Taxed - taxAmount10Taxed;
    }

    setTotal(sumTotalNotTaxed + sumTotalTaxed);
    setSubTotal(sumSubTotalNotTaxed + sumSubTotalTaxed);
    setTax8PercentTotal(taxAmount8NotTaxed + taxAmount8Taxed);
    setTax10PercentTotal(taxAmount10NotTaxed + taxAmount10Taxed);
  }

  // With all items that have taxed = true
  function withAllItemsTaxed(arrayNumber) {
    let sumTotal = 0;
    let sumItemTax8 = 0;
    let sumItemTax10 = 0;

    if (arrayNumber && arrayNumber.length > 0) {
      arrayNumber.map((row) => {
        if (row.taxed === true) {
          // Total of items that included tax
          sumTotal += row.amount ? row.amount : 0;

          if (row.taxRate === 0.08) {
            // Sum price of items having tax = 8%
            sumItemTax8 += row.amount ? row.amount : 0;
          }

          if (row.taxRate === 0.1) {
            // Sum price of items having tax = 10%
            sumItemTax10 += row.amount ? row.amount : 0;
          }
        }
      });
    }
    return { sumTotal: sumTotal, sumItemTax8, sumItemTax10 };
  }

  // With items that have taxed = false
  function withAllItemsNotTaxed(arrayNumber) {
    let sumSubTotal = 0;
    let sumItemTax8 = 0;
    let sumItemTax10 = 0;

    if (arrayNumber && arrayNumber.length > 0) {
      arrayNumber.map((row) => {
        if (row.taxed === false) {
          // Sum of all subtotal
          sumSubTotal += row.amount ? row.amount : 0;

          // Sum price of items having tax = 8%
          if (row.taxRate === 0.08) {
            sumItemTax8 += row.amount ? row.amount : 0;
          }

          // Sum of all items have tax = 10%
          if (row.taxRate === 0.1) {
            sumItemTax10 += row.amount ? row.amount : 0;
          }
        }
      });
    }
    return {
      sumSubTotal: sumSubTotal,
      sumItemTax8: sumItemTax8,
      sumItemTax10: sumItemTax10,
    };
  }
  
  var image = item.menuImage && item.menuImage.length > 0 && item.menuImage[0].image ? 
                  item.menuImage[0].image : props.NO_IMAGE_MENU;
  var shopName = item?.shopName ? item.shopName : null;
  var menuName = item?.menuName ? item.menuName : null;
  var userKidNumUse = item?.userKidNumUse ? item.userKidNumUse : 0;
  var usedAmountPerKid = item.usedAmount ? (item.usedAmount / (userKidNumUse || 1)) : 0;
  var imageThank = item.gochiKidThanksMessage && item.gochiKidThanksMessage.length > 0 && item.gochiKidThanksMessage[0].thanksImageUrl ? 
                  item.gochiKidThanksMessage[0].thanksImageUrl : props.NO_IMAGE_MENU;
  const gochiKidGroupId = item.gochiKidGroupId ? "&gochiKidGroupId=" + item.gochiKidGroupId : "";

  return (
    <div
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal gochi-kid-used-modal ${props.showPopupGochiKidUsedDetail ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.handleClosePopupGochiKidUsedDetail}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="body-gochi-kid-used-modal">
        <div className="gochi-kid-support-item">
          <dl className="list-description text-sm">
            <div className="gochi-kid-digest">
              <div className="gochi-kid-thumbnail">
                <div className="thumbnail">
                  <img
                    src={image}
                    alt="menu image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="shop-header-group">
                <div className="shop-header">
                  <div className="shop-name">
                    {shopName}
                  </div>
                  <h2 className='menu-name'>
                    {menuName}
                  </h2>
                </div>
                <hr />
                {item.menuId
                  ?
                    <div className="price-area">
                      <span className="unit">¥</span>
                      <span className="num">
                        {`${usedAmountPerKid?.toLocaleString()} x ${userKidNumUse}`}
                      </span>
                    </div>
                  :
                    <div className="price-area">
                      <div>
                        <div>
                          <span>利用人数: </span>
                          <span className="num">{userKidNumUse}</span>
                          <span className="unit">人</span>
                        </div>
                        <div>
                          <span>合計金額: </span>
                          <span className="num">{item.usedAmount?.toLocaleString()}</span>
                          <span className="unit">円</span>
                        </div>
                      </div>
                    </div>
                }
                {/*<div className="box-btn">
                  <button
                    className="btn btn-use-gochi"
                    disabled={props.thanksMessageIsNull}
                    onClick={() => {
                      if (props.numUserKidUse === 1) {
                        if (item.menuId) {
                          window.location.href = `/kodomo/use?shopId=${item.shopId}&menuId=${item.menuId}&userKidIds=${props.choiceUserKidId}${gochiKidGroupId}`;
                        } else {
                          // menu price
                          handleOpenInputGochiKidPriceModal(item.shopId);
                        }
                      } else if (props.numUserKidUse > 1) {
                        handleOpenSelectUserKid(item.shopId, item.menuId)
                      } else if (!props.numUserKidUse) {
                        props.handShowErrorUseGochiKidPopup("本日の食堂利用回数を超えました。");
                      }
                    }}
                  >
                    もう一度利用する
                  </button>
                </div>*/}
              </div>
            </div>
          </dl>
        </div>
        <div className="detail-box">
          <div className="detail-line">
            <div className="title">利用した日</div>
            <div className="text">{item.usedAt ? props.formatDate(item.usedAt) : ''}</div>
          </div>
          <div className="detail-line">
            <div className="title">店舗住所</div>
            <div className="text">{`${item.prefectures ? item.prefectures : ''} ${item.city ? item.city : ''} ${item.address ? item.address : ''} ${item.building ? item.building : ''}`}</div>
          </div>
          <div className="detail-line">
            <div className="title">投稿した感謝メッセージ</div>
            <div className="text">{item.thanksMessage}</div>
          </div>
          
          {item.gochiKidThanksMessage && item.gochiKidThanksMessage.length > 0 && item.gochiKidThanksMessage[0].thanksImageUrl && (
            <div className="detail-line thank-image-box">
              <div className="title">投稿した写真</div>
              <img
                className="image-thank"
                src={imageThank}
                alt="gochi kid used image"
              />
            </div>
          )}

          {item.gochiKidReceipt === 1 && !!item.receipt && (
            <>
              <div className="detail-line thank-image-box">
                <div className="title">登録したレシート写真</div>
                <img
                  className="image-receipt"
                  src={props.listReceiptImageGochiKid?.[props.indexGochiKidUsedChoose]?.imageBase64 || props.listReceiptImageGochiKid?.[props.indexGochiKidUsedChoose]?.image || props.receiptChoose?.receiptImage}
                  alt="gochi kid used image"
                />
              </div>
              <div className="detail-line thank-image-box">
                <div className="title">読み取り結果</div>
                <div className="receipt-box">
                  <div className="receipt-detail">
                    <div className="receipt-detail-row">
                      <div className="title-receipt">日付</div>
                      <div className="date-receipt">
                        <span className="date-text">{moment(item.receipt?.receiptDate).year()}</span>
                        <span>年</span>
                        <span className="date-text">{String(moment(item.receipt?.receiptDate).month() + 1).padStart(2, '0')}</span>
                        <span>月</span>
                        <span className="date-text">{String(moment(item.receipt?.receiptDate).date()).padStart(2, '0')}</span>
                        <span>日</span>
                      </div>
                    </div>
                    <div className="receipt-detail-row">
                      <div className="title-receipt">店舗</div>
                      <div className="shop-text-receipt">
                        {item.receipt?.receiptShopName ? `${item.receipt?.receiptShopName} ` : ''}
                        {item.receipt?.receiptBranch ? item.receipt?.receiptBranch : ''}
                      </div>
                    </div>
                    <div className="receipt-detail-row">
                      <div className="title-receipt">合計</div>
                      <div className="text-receipt">
                        {total ? total.toLocaleString() : 0}
                        <span className="yen">円</span>
                      </div>
                    </div>
                    <div className="receipt-detail-row">
                      <div className="title-receipt">小計</div>
                      <div className="text-receipt">
                        {subTotal && subTotal.toLocaleString()}
                        <span className="yen">円</span>
                      </div>
                    </div>
                    <div className="receipt-detail-row">
                      <div className="title-receipt">消費税(8%)</div>
                      <div className="text-receipt">
                        {tax8PercentTotal && tax8PercentTotal.toLocaleString()}
                        <span className="yen">円</span>
                      </div>
                    </div>
                    <div className="receipt-detail-row">
                      <div className="title-receipt">消費税(10%)</div>
                      <div className="text-receipt">
                        {tax10PercentTotal && tax10PercentTotal.toLocaleString()}
                        <span className="yen">円</span>
                      </div>
                    </div>
                  </div>
                  <table className="receipt-table">
                    <tbody>
                      {item.receipt?.receiptDetails && item.receipt?.receiptDetails.map(row => {
                        return (
                          <tr key={row.id}>
                            <td className="item-name">{row.name}</td>
                            <td>
                              {row.shopFoodFlag === 0 && (
                                <i className="warning-icon" />
                              )}
                            </td>
                            <td className="price-column">
                              <div className="justify-content-end">
                                <div>
                                  <div className="price-box">
                                    <span>{row.amount.toLocaleString()}</span>
                                    <span className="yen">円</span>
                                  </div>
                                  <div className="note-box">
                                    （
                                    {row.taxRate === 0
                                      ? "非抜"
                                      : row.taxed
                                      ? "税込"
                                      : "税抜"}
                                    ）
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {(showSelectUserKid || showInputGochiKidPrice ) && (
        <ModalGroup handleClosePopupDetailGochi={handleCloseModalGroup}>
          <SelectUserKidModal 
            isShow={showSelectUserKid}
            onHide={() => setShowSelectUserKid(false)}
            handleOpenInputGochiKidPriceModal={handleOpenInputGochiKidPriceModal}
            listUserKids={props.listUserKids}
            setChoiceUserKidIds={props.setChoiceUserKidIds}
            choiceShopId={choiceShopId}
            choiceMenuId={choiceMenuId}
            gochiKidGroupId={item.gochiKidGroupId}
          />

          <InputGochiKidPriceModal
            isShow={showInputGochiKidPrice}
            onHide={handleCloseInputGochiKidPriceModal}
            choiceShopId={choiceShopId}
            choiceUserKidIds={props.choiceUserKidIds}
            useAmount={props.useAmount}
            setUseAmount={props.setUseAmount}
            gochiKidGroupId={item.gochiKidGroupId}
          />
        </ModalGroup>
        )}
    </div>
  );
}
