import axios from "axios";
import { HTTP_API, makeRequest } from "../../HttpClient";

const API_BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';

export default class ReceiptService {
  static async uploadGochiKidReceipt(gochiKidUseId, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('gochiKidUseId', gochiKidUseId);
    const url = `${API_BASE_URL}/gochi-kid/upload-receipt`;
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async submitGochiKidReceipt(params) {
    const url = `${API_BASE_URL}/gochi-kid/submit-receipt`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async checkReceiptByAi(params) {
    const url = `${API_BASE_URL}/gochi-kid/recheck-receipt`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async checkReceiptByAdmin(params) {
    const url = `${API_BASE_URL}/gochi-kid/request-admin-approve-receipt`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async updateGochiKidReceipt(params) {
    const url = `${API_BASE_URL}/gochi-kid/update-receipt`;
    return makeRequest(HTTP_API.POST, url, params);
  }
}