import { it } from "date-fns/locale";
import moment from "moment";
import React, { useState } from "react";

export function SelectUserKidModal(props) {
  const [selectUserKid, setSelectUserKid] = useState(() => {
    let newArr = [];
      props.listUserKids.forEach(element => {
        newArr.push({
          userKidId: element.userKidId,
          displayName: element.displayName,
          canUse: element.canUse,
          checked: 0,
          canUseDate: element.canUseDate,
          amountKidCanUse: element.amountKidCanUse,
          firstUsed: element.firstUsed,
          flagPoor: element.flagPoor,
          debtAmount: element.debtAmount
        });
      });
      return newArr;
    }
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [showNoteAmount, setShowNoteAmount] = useState(false);
  const today = moment();
  
  const changeSelectUserKid = (value, index) => {
    let tmp = [...selectUserKid];
    tmp[index].checked = value;
    setSelectUserKid(tmp);
  }

  const gotoUseGochiKid = () => {
    let arr = [];
    props.setChoiceUserKidIds(() => []);
    props.setChoiceUserKids(() => []);
    selectUserKid.forEach(element => {
      if (element.checked) {
        arr.push(`userKidIds=${element.userKidId}`);
        props.setChoiceUserKidIds(ids => [...ids, element.userKidId]);
        props.setChoiceUserKids(kids => [...kids, element]);
      }
    });
    const accessToken = props.accessToken ? "&accessToken=" + props.accessToken : "";
    const accessCode = props.accessCode ? "&accessCode=" + props.accessCode : "";
    const gochiKidGroupId = props.gochiKidGroupId ? "&gochiKidGroupId=" + props.gochiKidGroupId : "";
    if (arr.length <=0) {
      setErrorMessage('必須入力です');
    } else {
      let userKidIds = arr.join("&");

      if (props.gochiKidReceiptShopChoice === 1) {
        props.onHide();
        props.handleShowPopupAcceptPolicyGochiKidReceipt(props.choiceShopId, props.gochiKidUnlimitShopChoice, props.choiceMenuId);
      } else if (props.choiceMenuId) {
        window.location.href = `/kodomo/use?shopId=${props.choiceShopId}&menuId=${props.choiceMenuId}&${userKidIds}${gochiKidGroupId}${accessToken}${accessCode}`;
      } else {
        // menu price
        props.onHide();
        props.handleOpenInputGochiKidPriceModal(props.choiceShopId, props.gochiKidUnlimitShopChoice);
      }
    }
  }

  return (
    <div
      className={`modal modal-profile modal-profile-02 modal-02 modal-lg select-user-kid-modal ${props.isShow ? 'is-show' : ''}`}
      tabIndex={-1}
    >
      <div className="header-register-user-kid">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={props.onHide}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className="title-modal-register">
        <div className="title">利用するお子さまの選択</div>
      </div>
      <div className="modal-body body-modal-register">
        <div className="title-warning">
          <div className="dot-red"></div>
          必須入力ですので、必ずご入力お願い致します
        </div>
        <div className="register-poor">
          <div className="register-title mt-16px">
            <div className="dot-red"></div>
            お子さま選択
          </div>
          <div className="form-block">
            {props.listUserKids.map((item, index) => {
              let divInfoUserKid = null;
              const canUseDate = moment(item.canUseDate);

              if (item.ageLimit && !item.canUse) {
                divInfoUserKid = (
                  <div className="info-user-kid-can-use-container age-note">
                    <span>
                      <i className="icon-age-note"/>
                    </span>
                    対象のお子さまは支援元の”{item.ageLimit}”制限({item.ageLimit}以下)により本サービスを利用できなくなりました。
                    <br />
                    これまでのご利用ありがとうございました。
                  </div>
                );
              } else if (item.canUseDate && !item.canUse && canUseDate.isAfter(today, 'day')) {
                divInfoUserKid = (
                  <div className="info-user-kid-can-use-container">
                    <div className="can-use-date-container">
                      <span>次回利用可能日</span>
                      <span className="can-use-date">{moment(item.canUseDate).format('YYYY年MM月DD日')}</span>
                      <a href="https://user-guide.gochimeshi.com/restart-date/" target="_blank" className="can-use-date-link">
                        <i className="icon-link-restart-date"/>
                      </a>
                    </div>
                  </div>
                );
              }

              return (
                <div style={{marginBottom: 24}} key={item.userKidId} >
                  <div className="field field-checkbox">
                    <label>
                      <input
                        className="before-icon"
                        name="bbbb"
                        type="checkbox"
                        disabled={!item.canUse || 
                          (item?.amountKidCanUse > 0 && item?.amountKidCanUse < props.priceChoiceMenu && item.firstUsed === 1 && item.flagPoor === 1) ||
                          (props.gochiKidUnlimitShopChoice === 0 && (props.priceChoiceMenu > (1000 - item.debtAmount) || item.debtAmount >= 1000))
                        }
                        checked={selectUserKid[index].checked}
                        onChange={(e) => {
                          if (e.target.checked) {
                            changeSelectUserKid(1, index);
                          } else {
                            changeSelectUserKid(0, index);
                          }
                        }}
                      />
                      <i className="icon" />
                      <span className="select-name-user-kid">{item.displayName}</span>
                    </label>
                  </div>
                  {!item.ageLimit && (
                    <div className="amount-can-use-now">
                      <span>利用可能残高</span>
                      <span className="amount-text">
                        {item.amountKidCanUse ? item.amountKidCanUse.toLocaleString() : 0}円
                      </span>
                    </div>
                  )}
                  {item.debtAmount > 0 && (!item.canUseDate || (item.canUseDate && !canUseDate.isAfter(today, 'day'))) && !item.ageLimit && (
                    <>
                      <div className="debt-amount-now">
                        <span>充当額</span>
                        <span className="amount-text">
                          {item.debtAmount ? item.debtAmount.toLocaleString() : 0}円
                        </span>
                      </div>
                      <div className="info-user-kid-can-use-container age-note debt-note">
                        <div>
                          <i className="icon-age-note"/>
                        </div>
                        前回のご利用時に利用すべきではない利用分があったため、今回のご利用可能残高の一部が前回分として充当されました。
                      </div>
                    </>
                  )}
                  {props.choiceMenuId && (!item.canUseDate || (item.canUseDate && !canUseDate.isAfter(today, 'day'))) &&
                    ((item.flagPoor === 1 && item.firstUsed === 1 && item?.amountKidCanUse < props.priceChoiceMenu && item?.amountKidCanUse > 0) ||
                      (props.gochiKidUnlimitShopChoice === 0 && (props.priceChoiceMenu > (1000 - item.debtAmount) || item.debtAmount >= 1000))) && (
                    <div className="warning-exceeding-usable-amount">
                      <i className="icon-warning"/>
                      利用メニューの価格（{props.priceChoiceMenu.toLocaleString()}円）が利用可能残高を超えているため、ご利用できません。
                    </div>
                  )}
                  {divInfoUserKid}  
                </div>
              )
            })}
          </div>
          <div className="title-warning">{errorMessage}</div>
          <div className={`mb-3 ${showNoteAmount ? 'border-black' : 'border-gray'}`}>
            <div className={`title-note-dropdown`} onClick={() => {
              setShowNoteAmount(!showNoteAmount);
            }}>
              <span>利用可能残高とは</span>
              {showNoteAmount ? (
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#angle-up" />
                </svg>
              ) : (
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#angle-down" />
                </svg>
              )}
            </div>
            {showNoteAmount && (
              <div className="text-note-dropdown">
                現時点の利用可能残高を表示しております。
                <br />
                利用可能日であっても、寄付金の状況によりご利用いただけない場合がございます。
                <br />
                あらかじめご了承ください。
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <button
            onClick={() => gotoUseGochiKid()}
            id="submitConfirm"
            className='btn btn-rect btn-confirm'
            disabled={!props.numUserKidUse}
          >
            確定する
          </button>
        </div>
        <div>
          <button
            onClick={props.onHide}
            id="submitConfirm"
            className={'btn btn-outline-rect btn-text'}
          >
            前の画面に戻る
          </button>
        </div>
      </div>
    </div>
  ); 
}
