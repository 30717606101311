import React, { useState } from "react";

export function GochiKidReceiptSuccessModal(props) {
  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-confirm show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-confirm"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="title-modal title-modal-charge">{props.title}</div>
        <div className={`modal-body`}>{props.message}</div>
        <div className="modal-footer">
          <button
            className="btn btn-rect btn-submit"
            onClick={() => props.onClose()}
          >
            マイページに戻る
          </button>
        </div>
      </div>
      <div
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
