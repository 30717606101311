import moment from "moment";
import React, { useEffect, useState } from "react";

export function GochiKidReceiptResultModal(props) {
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [tax8PercentTotal, setTax8PercentTotal] = useState(0);
  const [tax10PercentTotal, setTax10PercentTotal] = useState(0);
  const [warningReceipt, setWarningReceipt] = useState(false);

  const listTax = [
    { label: "0%", value: "0" },
    { label: "8%", value: "0.08" },
    { label: "10%", value: "0.1" },
  ];

  useEffect(() => {
    sumAllTotal(props.itemReceiptArr);
    checkWarningReceipt(props.itemReceiptArr);
  }, [props.itemReceiptArr]);

  function checkWarningReceipt(arrayNumber) {
    let warningFlag = false;

    if (arrayNumber && arrayNumber.length > 0) {
      arrayNumber.map((row) => {
        warningFlag = warningFlag || row.shopFoodFlag === 0;
      });
    }

    setWarningReceipt(warningFlag);
  }

  function sumAllTotal(arrayNumber) {
    // Calulate taxMount, total, and subtotal of all items that were not included tax
    const sumNotTaxed = withAllItemsNotTaxed(arrayNumber);
    let taxAmount8NotTaxed = 0;
    let taxAmount10NotTaxed = 0;
    let sumSubTotalNotTaxed = 0;
    let sumTotalNotTaxed = 0;
    if (sumNotTaxed) {
      taxAmount8NotTaxed = Math.floor(sumNotTaxed.sumItemTax8 * 0.08);
      taxAmount10NotTaxed = Math.floor(sumNotTaxed.sumItemTax10 * 0.1);
      sumSubTotalNotTaxed = sumNotTaxed.sumSubTotal;

      sumTotalNotTaxed =
        sumSubTotalNotTaxed + taxAmount8NotTaxed + taxAmount10NotTaxed;
    }

    // Calulate taxMount, total, and subtotal of all items that were included tax
    const sumTaxed = withAllItemsTaxed(arrayNumber);
    let taxAmount8Taxed = 0;
    let taxAmount10Taxed = 0;
    let sumSubTotalTaxed = 0;
    let sumTotalTaxed = 0;
    if (sumTaxed) {
      // Công thức ban đầu là Math.floor(((sumTaxed.sumItemTax8) / 1.08) * 0.08);
      // nhưng do phép tính "sumTaxed.sumItemTax8) / 1.08" bị lỗi phép chia với phân số (ví dụ 1080/1.08 đúng ra phải bằng 1000 nhưng máy tính tính ra thành 999.999999999)
      // nên để tránh lỗi trên thì sẽ nhân cả tử và mẫu vs 100 để k phải làm phép tính chia cho số thập phân. Sửa tương tự vs taxAmount10Taxed
      // See more: https://stackoverflow.com/questions/15383376/javascript-division-giving-wrong-answer
      taxAmount8Taxed = Math.floor(((sumTaxed.sumItemTax8 * 100) / 108) * 0.08);
      taxAmount10Taxed = Math.floor(((sumTaxed.sumItemTax10 * 10) / 11) * 0.1);
      sumTotalTaxed = sumTaxed.sumTotal;

      sumSubTotalTaxed = sumTotalTaxed - taxAmount8Taxed - taxAmount10Taxed;
    }

    setTotal(sumTotalNotTaxed + sumTotalTaxed);
    setSubTotal(sumSubTotalNotTaxed + sumSubTotalTaxed);
    setTax8PercentTotal(taxAmount8NotTaxed + taxAmount8Taxed);
    setTax10PercentTotal(taxAmount10NotTaxed + taxAmount10Taxed);
  }

  // With all items that have taxed = true
  function withAllItemsTaxed(arrayNumber) {
    let sumTotal = 0;
    let sumItemTax8 = 0;
    let sumItemTax10 = 0;

    if (arrayNumber && arrayNumber.length > 0) {
      arrayNumber.map((row) => {
        if (row.taxed === true) {
          // Total of items that included tax
          sumTotal += row.amount ? row.amount : 0;

          if (row.taxRate === 0.08) {
            // Sum price of items having tax = 8%
            sumItemTax8 += row.amount ? row.amount : 0;
          }

          if (row.taxRate === 0.1) {
            // Sum price of items having tax = 10%
            sumItemTax10 += row.amount ? row.amount : 0;
          }
        }
      });
    }
    return { sumTotal: sumTotal, sumItemTax8, sumItemTax10 };
  }

  // With items that have taxed = false
  function withAllItemsNotTaxed(arrayNumber) {
    let sumSubTotal = 0;
    let sumItemTax8 = 0;
    let sumItemTax10 = 0;

    if (arrayNumber && arrayNumber.length > 0) {
      arrayNumber.map((row) => {
        if (row.taxed === false) {
          // Sum of all subtotal
          sumSubTotal += row.amount ? row.amount : 0;

          // Sum price of items having tax = 8%
          if (row.taxRate === 0.08) {
            sumItemTax8 += row.amount ? row.amount : 0;
          }

          // Sum of all items have tax = 10%
          if (row.taxRate === 0.1) {
            sumItemTax10 += row.amount ? row.amount : 0;
          }
        }
      });
    }
    return {
      sumSubTotal: sumSubTotal,
      sumItemTax8: sumItemTax8,
      sumItemTax10: sumItemTax10,
    };
  }

  const handleChange = (value, index) => {
    props.setItemReceiptArr((s) => {
      const newArr = JSON.parse(JSON.stringify(s));;
      const newVal = value ? value : 0;
      if (!isNaN(+newVal)) {
        newArr[index].amount = newVal ? parseInt(newVal) : "";
      } else {
        newArr[index].amount = "";
      }

      return newArr;
    });
  };

  const onRemoveRow = (index) => {
    const newArr = [...props.itemReceiptArr];
    newArr.splice(index, 1);
    props.setItemReceiptArr(newArr);
  };

  const onUpdateTaxRow = (value, index) => {
    console.log("value", value);
    console.log("index", index);
    props.setItemReceiptArr((s) => {
      const newArr = JSON.parse(JSON.stringify(s));;
      const newVal = value ? value : 0;

      if (newVal) {
        newArr[index].taxRate = parseFloat(newVal);
      } else {
        newArr[index].taxRate = 0;
      }

      console.log("newArr", newArr);
      return newArr;
    });
  };

  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-result-modal show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-receipt-result-modal"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => props.onClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">
          <div className="title required-dot">
            <div className="dot-red"></div>
            レシート登録 結果確認
          </div>
        </div>
        <div className={`modal-body`}>
          <div className="preview-image-box">
            <img
              src={props.receiptImageBase64?.imageBase64 || props.receiptImageBase64?.image || props.receiptChoose?.receiptImage}
              width="100%"
              height="auto"
              className="image-receipt"
            />
          </div>
          <div className="receipt-detail">
            <div className="receipt-detail-row">
              <div className="title">日付</div>
              <div className="date">
                {props.receiptChoose?.receiptDate && (
                  <>
                    <span className="date-text">
                      {moment(props.receiptChoose?.receiptDate).year()}
                    </span>
                    <span>年</span>
                    <span className="date-text">
                      {String(
                        moment(props.receiptChoose?.receiptDate).month() + 1
                      ).padStart(2, "0")}
                    </span>
                    <span>月</span>
                    <span className="date-text">
                      {String(
                        moment(props.receiptChoose?.receiptDate).date()
                      ).padStart(2, "0")}
                    </span>
                    <span>日</span>
                  </>
                )}
              </div>
            </div>
            <div className="receipt-detail-row">
              <div className="title">店舗</div>
              <div className="shop-text">
                {props.receiptChoose?.receiptShopName ? `${props.receiptChoose?.receiptShopName} ` : ''}
                {props.receiptChoose?.receiptBranch ? props.receiptChoose?.receiptBranch : ''}
              </div>
            </div>
            <div className="receipt-detail-row">
              <div className="title">合計</div>
              <div className="text">
                {total ? total.toLocaleString() : 0}
                <span className="yen">円</span>
              </div>
            </div>
            <div className="receipt-detail-row">
              <div className="title">小計</div>
              <div className="text">
                {subTotal && subTotal.toLocaleString()}
                <span className="yen">円</span>
              </div>
            </div>
            <div className="receipt-detail-row">
              <div className="title">消費税(8%)</div>
              <div className="text">
                {tax8PercentTotal && tax8PercentTotal.toLocaleString()}
                <span className="yen">円</span>
              </div>
            </div>
            <div className="receipt-detail-row">
              <div className="title">消費税(10%)</div>
              <div className="text">
                {tax10PercentTotal && tax10PercentTotal.toLocaleString()}
                <span className="yen">円</span>
              </div>
            </div>
          </div>
          <div className="receipt-list-item">
            <div className="title">品目</div>
            {props.itemReceiptArr?.length > 0 ? (
              <>
                <table className="receipt-table">
                  <tbody>
                    {props.itemReceiptArr &&
                      props.itemReceiptArr.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td>
                              <button
                                className="btn-delete-receipt-item"
                                onClick={() => onRemoveRow(index)}
                              >
                                <i className="delete-item-icon" />
                              </button>
                            </td>
                            <td className="text-item">{row.name}</td>
                            <td className="row-warning">
                              {row.shopFoodFlag === 0 && (
                                <i className="warning-icon" />
                              )}
                            </td>
                            <td>
                              <div className="price-box">
                                <input
                                  className="input-price-receipt"
                                  type="number"
                                  min="0"
                                  pattern="[0-9]*"
                                  value={row.amount}
                                  id={row.id}
                                  onChange={(e) => {
                                    const value =
                                      parseInt(
                                        e.target.value.replace(/[^\d]/, "")
                                      ) || "";
                                    e.preventDefault();
                                    e.persist();
                                    handleChange(value, index);
                                  }}
                                  onWheel={(e) => e.target.blur()} 
                                />
                                <span className="yen">円</span>
                              </div>
                              <div className="tax-rate-box">
                                <select
                                  className="select-tax-receipt"
                                  value={row.taxRate.toLocaleString()}
                                  onChange={(e) => {
                                    onUpdateTaxRow(
                                      e.target.value ? e.target.value : 0,
                                      index
                                    );
                                  }}
                                >
                                  {listTax.map((item, index) => (
                                    <option value={item.value} key={index}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                                <span className="tax-note">
                                  {row.taxRate === 0
                                    ? "非抜"
                                    : row.taxed
                                    ? "税込"
                                    : "税抜"}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {warningReceipt && (
                  <div className="receipt-warning-box">
                    <i className="receipt-warning-icon" />
                    <div>
                      お子さまの飲食品以外の品目を読み取りました。
                      <br />
                      ただし、読み取りの精度は完全ではありません。
                      <br />
                      ご確認のうえ、お子さまの飲食品以外の品目を削除し、登録にお進みください。
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="notice-not-have-item">
                登録品目がないため、登録できません。レシート画像を再選択してください。
              </div>
            )}
          </div>
          <div className="modal-footer">
            {props.itemReceiptArr?.length > 0 && total > 0 && (
              <button
                className="btn btn-rect btn-submit"
                onClick={() => props.onSubmit()}
              >
                読み取る
              </button>
            )}
            <button
              onClick={() => props.onClose()}
              className={"btn btn-outline-rect btn-text"}
            >
              前の画面に戻る
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => props.onClose()}
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
